
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/common/Header";
import Loader from "../components/common/Loader";
import axios from "axios";
import Banner from "../components/pages/home/Banner";
import Progress from "../components/pages/home/Progress";
import FeaturedIn from "../components/pages/home/FeaturedIn";
import Why from "../components/pages/home/Why";
import SocialFi from "../components/pages/home/SocialFi";
import Tokenomics from "../components/pages/home/Tokenomics";
import Join from "../components/pages/home/Join";
import Universe from "../components/pages/home/Universe";
import Team from "../components/pages/home/Team";
import Audit from "../components/pages/home/Audit";
import Roadmap from "../components/pages/home/Roadmap";
import Faqs from "../components/pages/home/faqs";
import JoinApp from "../components/pages/home/JoinApp";
import Footer from "../components/common/Footer";
import Script from "next/script";
export default function Index({ title, description, canonical }) {
    const { t } = useTranslation();
    const seo = t('common:seo', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [apiData, setApiData] = useState({
        current_token_price: "0",
        next_token_price: "0",
        percent_tokens: "0",
        percent_usd: "0",
        stage_name: "Stage 1",
        tokens_left_by_stage: "0",
        tokens_sold: "0",
        total_tokens: "0",
        total_usd: "0",
        usd_raised: "0"
    });
    const getData = async () => {
        return await axios.get('/api/get-data', {
            headers: {
                "Content-Type": "application/json"
            }
        });
    };
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        let apiData = new Promise((resolve, reject) => {
            getData().then(data => {
                resolve();
                setApiData(data.data);
            }).catch((err) => {
                resolve();
                setApiData({
                    current_token_price: "0",
                    next_token_price: "0",
                    percent_tokens: "0",
                    percent_usd: "0",
                    stage_name: "Stage 1",
                    stages_list: [],
                    tokens_left_by_stage: "0",
                    tokens_sold: "0",
                    total_tokens: "0",
                    total_usd: "0",
                    usd_raised: "0"
                });
            });
        });
        Promise.all([appLoading, apiData]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        headline: seo.home.title,
        description: seo.home.description,
        author: {
            "@type": "WebSite",
            name: "GoodEggToken",
            url: "https://goodeggtoken.com/"
        },
        image: "",
        datePublished: "2024-08-10",
        dateModified: "2024-09-24"
    };
    return (<MainLayout title={seo.home.title} description={seo.home.description} keywords={seo.home.keywords} page={'home'} loaded={loaded}>
            <Script id="faq-schema" type="application/ld+json" dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd)
        }}/>
            <div className='banner_bg'>
                <Header t={t}/>
                <Banner t={t}/>
            </div>
            <main className='main'>
                <Progress t={t} apiData={apiData}/>
                <FeaturedIn t={t}/>
                <Why t={t}/>
                <SocialFi t={t}/>
                <Tokenomics t={t} apiData={apiData}/>
                <Join t={t}/>
                <Universe t={t}/>
                <Team t={t}/>
                <Audit t={t}/>
                <Roadmap t={t}/>
                <Faqs t={t}/>
                <JoinApp t={t}/>
                <Loader isLoading={isLoading} loaded={loaded}/>
            </main>
            <Footer t={t}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__192c28627f0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192c28627f0__ as getStaticProps }
  