
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Tokenomics = ({ t, apiData }) => {
    const tokenomics = t('home:tokenomics', {}, { returnObjects: true });
    return (<section className='tokenomics' id={'tokenomics'}>
            <div className="container">
                <div className="tokenomics_wrapper">
                    <picture>
                        <img src={`/img/home/tokenomics/title.svg`} alt="GOODEGGS" width={1277} height={224} className='tokenomics_title' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100}/>
                    </picture>
                    <div className='tokenomics_data'>
                        <ul className="data_info">{tokenomics.info.map((el, i) => {
            return <li data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 + (i + 1)} key={el.title} className='data_info_item'>
                                    <p className='data_info_item_value'>{el.key === 'price' ? `$${apiData?.current_token_price}` : el.title}</p>
                                    <h3 className='data_info_item_title'>{el.value}</h3>
                                </li>;
        })}</ul>
                        <div className="data_umberto">
                            <div className="data_umberto_tooltip">
                                <div className="tooltip">
                                    <h4>{tokenomics.umberto.title}</h4>
                                    <p>{tokenomics.umberto.text}</p>
                                </div>
                            </div>
                            <div className="data_umberto_wrapper">
                                <picture>
                                    <source media="(min-width: 577px)" srcSet={`/img/home/tokenomics/umberto.webp`} type="image/webp"/>
                                    <source media="(max-width: 576px)" srcSet={`/img/home/tokenomics/umberto_small.webp`} type="image/webp"/>
                                    <source media="(min-width: 577px)" srcSet={`/img/home/tokenomics/umberto.png`}/>
                                    <source media="(max-width: 576px)" srcSet={`/img/home/tokenomics/umberto_small.png`}/>
                                    <img src={`/img/home/tokenomics/umberto.png`} alt="GOODEGGS Tokenommics" width={288} height={507} className='umberto_img'/>
                                </picture>
                                <img src={`/img/home/tokenomics/umberto_coin.svg`} alt="GOODEGGS Tokenommics" width={33} height={921} className='umberto_coin'/>
                            </div>
                        </div>
                        <div className="data_chart">
                            <ul className="data_chart_wrapper">{tokenomics.chart.map(element => {
            let arr = Array.apply(null, Array(element.value)).map((el, i) => element.color);
            return arr.map((el, i) => {
                return <li data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={10 + (i + 1)} key={el + i} className={'data_chart_item'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="37" viewBox="0 0 30 37" fill={el}>
                                                <path d="M30 22.2817C30 32.2229 23.2843 36.2817 15 36.2817C6.71573 36.2817 0 32.2229 0 22.2817C0 12.3406 6.71573 0.281738 15 0.281738C23.2843 0.281738 30 12.3406 30 22.2817Z" fill={el}/>
                                            </svg>
                                        </li>;
            });
        })}</ul>
                            <ul className='data_chart_legend'>
                                {tokenomics.chart.map(el => {
            return <li key={el.title} className={'legend_item'}>
                                            <div className={'circle'} style={{ background: el.color }}/>
                                            <div className="data">
                                                <h4>{el.title}</h4>
                                                <p>{el.value}</p>
                                            </div>
                                        </li>;
        })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default Tokenomics;

    async function __Next_Translate__getStaticProps__192c2862a8f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Tokenomics/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192c2862a8f__ as getStaticProps }
  